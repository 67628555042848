type Props = {
  width?: number
  height?: number
  fill?: string
  className?: string
}

const InstagramIcon = ({ width, height, fill, className }:Props) => (
  <svg
    className={`svg-icon svg-icon-instagram ${className ? className : ''}`}
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill={fill}
    role="img"
    aria-label="Instagramアイコン"
  >
    <path d="M14.216,2.643a14.588,14.588,0,0,0-4.852.939A9.788,9.788,0,0,0,5.826,5.9a9.8,9.8,0,0,0-2.3,3.545,14.7,14.7,0,0,0-.92,4.857c-.094,2.129-.115,2.814-.1,8.245s.034,6.112.138,8.246a14.62,14.62,0,0,0,.939,4.851,10.223,10.223,0,0,0,5.861,5.838,14.682,14.682,0,0,0,4.856.92c2.129.093,2.814.115,8.244.1s6.113-.034,8.246-.136a14.633,14.633,0,0,0,4.85-.939,10.221,10.221,0,0,0,5.837-5.861,14.63,14.63,0,0,0,.92-4.854c.093-2.135.115-2.816.1-8.247s-.035-6.111-.136-8.244a14.608,14.608,0,0,0-.939-4.853A9.819,9.819,0,0,0,39.109,5.83a9.761,9.761,0,0,0-3.547-2.3,14.636,14.636,0,0,0-4.856-.92c-2.129-.092-2.814-.116-8.246-.105s-6.112.034-8.245.138M14.45,38.8a11.1,11.1,0,0,1-3.714-.68,6.228,6.228,0,0,1-2.3-1.492,6.16,6.16,0,0,1-1.5-2.3,11.077,11.077,0,0,1-.7-3.713c-.1-2.108-.12-2.74-.132-8.08s.009-5.972.1-8.08a11.068,11.068,0,0,1,.68-3.714,6.193,6.193,0,0,1,1.492-2.3,6.174,6.174,0,0,1,2.3-1.5,11.075,11.075,0,0,1,3.712-.7c2.109-.1,2.741-.12,8.08-.132s5.972.008,8.082.1a11.061,11.061,0,0,1,3.713.68,6.621,6.621,0,0,1,3.8,3.79,11.019,11.019,0,0,1,.695,3.71c.1,2.109.123,2.742.133,8.08s-.009,5.972-.1,8.08a11.113,11.113,0,0,1-.68,3.716,6.624,6.624,0,0,1-3.79,3.8,11.078,11.078,0,0,1-3.71.7c-2.109.1-2.741.12-8.082.132s-5.971-.01-8.08-.1m16.3-26.985a2.4,2.4,0,1,0,2.4-2.4,2.4,2.4,0,0,0-2.4,2.4M12.23,22.523a10.27,10.27,0,1,0,10.249-10.29A10.269,10.269,0,0,0,12.23,22.523m3.6-.007a6.667,6.667,0,1,1,6.68,6.654,6.667,6.667,0,0,1-6.68-6.654" transform="translate(-2.499 -2.503)"/>
  </svg>
)

export default InstagramIcon
