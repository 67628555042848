import React, { useRef, useEffect } from "react";
import s from './Modal.module.scss'

// type Props = {
//   setModal: Function;
// };

// const Modal: FC<Props> = ({
//   setModal
// }) => {
const Modal = () => {
    const refCloser = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    refCloser.current!.addEventListener('click', e=>{
      document.body.removeAttribute('data-is')
      // setModal(false)
    })
  }, []);

  return (
    <section className={s.root}>
      <button ref={refCloser} className={s.closer}>
        <img src={`${process.env.PUBLIC_URL}/assets/img/close.svg`} width="160" height="160" alt="閉じる" loading="lazy" />
      </button>
      <div className={s.inner}>
        <h2 className={s.title}>
          <span className={s.title__en}>Fan Letter</span>
          <span className={s.title__jp}>ファンレター、プレゼントの受付について</span>
        </h2>
        <div className={s.contents}>
          <section className={s.addressee}>
            <h3 className={s['addressee-title']}>
              <span className={s['addressee-title__inner']}>送付先</span>
            </h3>
            <p className={s['addressee__text']}>〒151-0053<br />
            東京都渋谷区代々木5丁目55-6-301 <br />
            天寿光希 オフィシャルファンクラブ<br />
            運営事務局</p>
          </section>
          <section className={s.precautions}>
            <h3 className={s['precautions-title']}>
              <span className={s['precautions-title__inner']}>プレゼント送付時の注意事項</span>
            </h3>
            <p className={s['precautions__text']}>既製品(新品)のみ受付させていただきます。<br />
            ※以下のプレゼントについては事務局のルール上、受け取ることができません。<br />
            お送り頂きましても、受取拒否の対応となってしまいますのでご了承ください。</p>
            <ul className={s.list}>
              <li className={s.item}>・開封された物 / 使用済みの物</li>
              <li className={s.item}>・危険物 (火薬・花火・刃物)</li>
              <li className={s.item}>・生き物（動物・植物（生花含む）・虫など）</li>
              <li className={s.item}>・生もの（弁当、鮮魚、生肉、果物、野菜など）</li>
              <li className={s.item}>・常温保存以外の食品</li>
              <li className={s.item}>・自己制作物（手紙やイラストなどの紙類以外を除く、食品や雑貨）</li>
            </ul>
            <p className={s.text}>感染症予防対策を行った上で本人へ受け渡しをさせていただきます。<br />
            また、受取日時の指定につきましては、<br />
            指定日に受け取れない場合がございますので予めご了承ください。</p>
          </section>
        </div>
      </div>
    </section>
  )
}

export default Modal