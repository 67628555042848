import React, { useMemo, useEffect, useRef, RefObject, createRef } from "react"
import {isWebpSupported} from 'react-image-webp/dist/utils';
// import FastImage from 'react-native-fast-image'
import s from './Visual.module.scss'

import { gsap } from "gsap"

// FastImage.preload([
//   { uri: './assets/img/visual/01.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/02.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/04.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/05.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/06.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/07.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/08.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/09.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/10.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/12.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/13.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/14.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/15.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/16.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/17.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/18.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/19.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/21.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/22.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/24.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/25.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/26.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/27.webp', headers: { Authorization: 'someAuthToken' } },
//   { uri: './assets/img/visual/28.webp', headers: { Authorization: 'someAuthToken' } }
// ])

type VisualItemType = {
  type: string;
  name: string;
  time: number;
  loading: 'lazy' | 'eager';
};

const Visual = () => {
  const refVisual = useRef<HTMLDivElement>(null);
  // const refVideo = useRef<HTMLVideoElement[]>(null);
  const refsVideo = useRef<RefObject<HTMLVideoElement>[]>([]);
  // const refsVideoSp = useRef<RefObject<HTMLVideoElement>>(null);

  // const [device, setDevice] = useState('');

  const visualData = useMemo(() => {
    const data: VisualItemType[] = [
      { type: 'jpg', name: '01', time: 0.5, loading: 'eager' },
      { type: 'jpg', name: '02', time: 0.5, loading: 'eager' },
      { type: 'mp4', name: '03', time: 1.368033, loading: 'eager' },
      { type: 'jpg', name: '04', time: 0.5, loading: 'eager' },
      { type: 'jpg', name: '05', time: 0.5, loading: 'eager' },
      { type: 'jpg', name: '06', time: 0.5, loading: 'eager' },
      { type: 'jpg', name: '07', time: 0.5, loading: 'eager' },
      { type: 'jpg', name: '08', time: 0.5, loading: 'eager' },
      { type: 'jpg', name: '09', time: 0.5, loading: 'eager' },
      { type: 'jpg', name: '10', time: 0.5, loading: 'eager' },
      { type: 'mp4', name: '11', time: 1.5015, loading: 'eager' },
      { type: 'jpg', name: '12', time: 0.5, loading: 'lazy' },
      { type: 'jpg', name: '13', time: 0.5, loading: 'lazy' },
      { type: 'jpg', name: '14', time: 0.5, loading: 'lazy' },
      { type: 'jpg', name: '15', time: 0.5, loading: 'lazy' },
      { type: 'jpg', name: '16', time: 0.5, loading: 'lazy' },
      { type: 'jpg', name: '17', time: 0.5, loading: 'lazy' },
      { type: 'jpg', name: '18', time: 0.5, loading: 'lazy' },
      { type: 'jpg', name: '19', time: 0.5, loading: 'lazy' },
      { type: 'mp4', name: '20', time: 1.5015, loading: 'lazy' },
      { type: 'jpg', name: '21', time: 0.5, loading: 'lazy' },
      { type: 'jpg', name: '22', time: 0.5, loading: 'lazy' },
      { type: 'mp4', name: '23', time: 1.5015, loading: 'lazy' },
      { type: 'jpg', name: '24', time: 0.5, loading: 'lazy' },
      { type: 'jpg', name: '25', time: 0.5, loading: 'lazy' },
      { type: 'jpg', name: '26', time: 0.5, loading: 'lazy' },
      { type: 'jpg', name: '27', time: 0.5, loading: 'lazy' },
      { type: 'jpg', name: '28', time: 0.5, loading: 'lazy' },
      { type: 'mp4', name: '29', time: 2.5025, loading: 'lazy' }
    ]
    return data
  }, [])

  visualData.forEach((_, index) => {
    refsVideo.current[index] = createRef<HTMLVideoElement>()
  })

  // const mql = window.matchMedia('screen and (min-width: 768px)');
  // // 初回チェック
  // const checkBreakPoint = (_mql:any) => {
  //   setDevice((!_mql.matches) ? 'sp' : 'dt');
  // }
  // // ブレイクポイントの瞬間に発火
  // mql.addListener(checkBreakPoint);

  // const suffix = (device !== 'sp') ? '@pc' : '';
  // console.log({suffix})

  useEffect(() => {
    let changeAnimationFlag:boolean = false;
    let index:any = 1;
    let nextIndex:any = null;
    let z:any = 0;
    let elm:any = null;
    
    // checkBreakPoint(mql);

    const targetParent = refVisual.current;
    const item = targetParent?.querySelectorAll('[data-time]');
    const count = item!.length

    let max:any = count - 1;

    const imageChange = () => {
      const timeIndex = (index > 0) ? index - 1 : max;
      const time = (item![timeIndex] as HTMLInputElement | null)?.dataset.time;

      nextIndex = (nextIndex < max) ? index + 1 : 0;
      const type = visualData![index].type;
      if (type === 'mp4') {
        elm = targetParent?.querySelector('#video-' + index) as HTMLVideoElement;
      }

      gsap.to(item![index], {
        opacity: 1,
        zIndex: z,
        duration: 0,
        delay: time,
        onComplete: ()=>{
          z++;
          index = (index < max) ? ++index : 0;
          
          if (type === 'mp4') elm?.play();
    
          const ww = window.innerWidth * 2;
          window.requestAnimationFrame(()=>{
            if (window.scrollY < ww) {
              imageChange();
              changeAnimationFlag = true;
            } else {
              changeAnimationFlag = false;
            }
          })
        }
      });
    }
    
    const toggleVisibility = () => {
      const ww = window.innerWidth * 2;
      window.requestAnimationFrame(()=>{
        if (!changeAnimationFlag && window.scrollY < ww) {
          imageChange();
          changeAnimationFlag = !changeAnimationFlag;
        }
      })
    }

    if (window.innerWidth >= 768) {
      setTimeout(e=>{
        imageChange();
      }, 1000)
      window.addEventListener('scroll', toggleVisibility)
    } else {
      const videoSpElm = targetParent?.querySelector('#video-sp') as HTMLVideoElement;
      videoSpElm?.play();
    }
  
  }, [visualData]);

  return (
    <div ref={refVisual} className={s.visual}>
      {
        window.innerWidth >= 768 ? (
          visualData.map((visual: VisualItemType, idx: number) => {
            return (
              <figure className={s.item} key={idx} data-time={visual.time}>
                <span className="c-clip">
                  {visual.type !== 'jpg' ? (
                    <video ref={refsVideo.current[idx]} muted playsInline preload="preload" width="780" id={`video-${idx}`}>
                      <source src={`${process.env.PUBLIC_URL}/assets/img/visual/${visual.name}@pc.${visual.type}`} type="video/mp4" />
                    </video>
                  ) : isWebpSupported() ? (
                    <img src={`${process.env.PUBLIC_URL}/assets/img/visual/${visual.name}@pc.webp`} width="1298" height="700" alt="" loading={`${visual.loading}`} />
                  ) : (
                    <img src={`${process.env.PUBLIC_URL}/assets/img/visual/${visual.name}@pc.${visual.type}`} width="1298" height="700" alt="" loading={`${visual.loading}`} />
                  )}
                </span>
              </figure>
            )
          })
        ) : (
          <figure className={s.item}>
            <span className="c-clip">
              <video muted playsInline loop preload="preload" width="780" id={`video-sp`}>
                <source src={`${process.env.PUBLIC_URL}/assets/img/visual/sp.mp4`} type="video/mp4" />
              </video>
            </span>
          </figure>
        )
      }
    </div>
  )
}

export default Visual