import {isWebpSupported} from 'react-image-webp/dist/utils';
import { ListBullet, ButtonArrow } from '../../../icons'
import s from './Fanclub.module.scss'

const Fanclub = () => {
  return (
    <section className={s.root}>
      <div className={s.inner}>
        <h2 className={s.title}>Fan Club</h2>
        <div className={s.group}>
          <section className={s.message}>
            <h3 className={s['message-title']}>
              <span className={s['message-title__label']}>天寿光希 公式ファンクラブ</span>
              <span className={s['message-title__name']}>てんてんたんたんCLUB</span>
            </h3>
            <p className={s.message__text}>日頃より天寿光希を応援いただきまして、<br />
            誠にありがとうございます。<br />
            オフィシャルファンクラブ<br data-display="sp" />
            「てんてんたんたんCLUB」では、<br />
            会員限定イベントへのお申し込み、<br data-display="sp" />
            オリジナルグッズの購入、<br />
            天寿光希による<br data-display="sp" />
            お誕生日お祝いメッセージや<br />
            オリジナルLINEグループへのご招待など、<br />
            さまざまなサービスを<br data-display="sp" />
            お楽しみいただけます。<br />
            今後とも天寿光希と共に<br />
            「てんてんたんたんCLUB」を<br data-display="sp" />
            盛り上げていただけますよう、<br />
            心よりお願い申し上げます。</p>
          </section>
          <section className={s.service}>
            <h3 className={s['service-title']}>
              <span className={s['service-title__crown']}>
                <img src={`${process.env.PUBLIC_URL}/assets/img/crown.svg`} width="27" height="21" alt="" loading="lazy" />
              </span>
              <span className={s['service-title__en']}>Service</span>
              <span className={s['service-title__text']}>プリティ会員様サービス内容</span>
              <span className={s['service-title__delimiter']}>
                <img src={`${process.env.PUBLIC_URL}/assets/img/delimiter.svg`} width="375" height="20" alt="" loading="lazy" />
              </span>
            </h3>
            <ul className={s.service__lists}>
              <li className={s.service__list}><ListBullet />オリジナル会員証の発行</li>
              <li className={s.service__list}><ListBullet />会員様限定グッズの購入権</li>
              <li className={s.service__list}><ListBullet />舞台チケットの優先お取次ぎ権</li>
              <li className={s.service__list}><ListBullet />会員様限定SNSコンテンツ<br data-display="sp" />（LINE）へのご招待</li>
              <li className={s.service__list}><ListBullet />会員様限定イベントの参加権</li>
              <li className={s.service__list}><ListBullet />Summer card・Birthday card・<br data-display="sp" />年賀状のお届け</li>
            </ul>
          </section>
          <div className={s.more}>
            <div className={s['more-label']}><span className={s['more-label__inner']}>天寿光希公式FAN CLUB<br />「てんてんたんたんCLUB」へ</span></div>
            <div className={s.more__button}>
              <a href="https://miitus.jp/t/mitsuki_ten10/" target="_blank" rel="noopener noreferrer" className={s.more__link}>Click For More<ButtonArrow /></a>
            </div>
            <div className={s.more__illust}>
            {isWebpSupported() ? (
              <img src={`${process.env.PUBLIC_URL}/assets/img/illust-more.webp`} width="121" height="139" alt="" loading="lazy" />
            ) : (
              <img src={`${process.env.PUBLIC_URL}/assets/img/illust-more.png`} width="121" height="139" alt="" loading="lazy" />
            )}
            </div>
          </div>
        </div>
      </div>
      <div className={s.illust}>
      {isWebpSupported() ? (
        <img src={`${process.env.PUBLIC_URL}/assets/img/illust-service-01.webp`} width="115" height="106" alt="" loading="lazy" />
      ) : (
        <img src={`${process.env.PUBLIC_URL}/assets/img/illust-service-01.png`} width="115" height="106" alt="" loading="lazy" />
      )}
      </div>
      <figure className={s.image}>
        <span className={s.clip}>
        {isWebpSupported() ? (
          <picture>
            <source media="(min-width: 768px)" srcSet={`${process.env.PUBLIC_URL}/assets/img/fanclub-image@pc.webp`} width="1366" height="768" />
            <img src={`${process.env.PUBLIC_URL}/assets/img/fanclub-image.webp`} width="750" height="1080" alt="" loading="lazy" />
          </picture>
        ) : (
          <picture>
            <source media="(min-width: 768px)" srcSet={`${process.env.PUBLIC_URL}/assets/img/fanclub-image@pc.jpg`} width="1366" height="768" />
            <img src={`${process.env.PUBLIC_URL}/assets/img/fanclub-image.jpg`} width="750" height="1080" alt="" loading="lazy" />
          </picture>
        )}
        </span>
      </figure>
    </section>
  )
}

export default Fanclub