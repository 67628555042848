type Props = {
  width?: number
  height?: number
  fill?: string
  className?: string
}

const ListBullet = ({ width, height, fill, className }:Props) => (
  <svg
    className={`svg-icon svg-icon-list-bullet ${className ? className : ''}`}
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill={fill}
    role="img"
    aria-label="リストアイコン"
  >
    <path d="M101.559,0,99.494,8.935,90.559,11l8.935,2.065L101.559,22l2.065-8.935L112.559,11l-8.935-2.065Z" transform="translate(-90.559)" fill="#838383"/>
  </svg>
)

export default ListBullet
