import React, { useRef } from "react";
import {isWebpSupported} from 'react-image-webp/dist/utils';
import { ButtonArrow, InstagramIcon, FacebookIcon } from '../../../icons'
import s from './Biography.module.scss'

// type Props = {
//   setModal: Function;
// };

// const Biography: FC<Props> = ({
//   setModal
// }) => {
const Biography = () => {
  const refModal = useRef<HTMLButtonElement>(null);
  const modalSwitch = () => {
    document.body.dataset.is = 'modal';
    // setModal(true)
  }

  return (
    <section className={s.root}>
      <div className={s.illust} data-illust="01">
      {isWebpSupported() ? (
        <img src={`${process.env.PUBLIC_URL}/assets/img/illust-tark.webp`} width="112" height="112" alt="" loading="lazy" />
      ) : (
        <img src={`${process.env.PUBLIC_URL}/assets/img/illust-tark.png`} width="112" height="112" alt="" loading="lazy" />
      )}
      </div>
      <div className={s.inner}>
        <h2 className={s.title}>Biography</h2>
        <ul className={s.history}>
          <li className={s.history__list}>
            <span className={s.history__year}>2003年</span>
            <span className={s.history__contents}>宝塚音楽学校入学。</span>
          </li>
          <li className={s.history__list}>
            <span className={s.history__year}>2005年</span>
            <span className={s.history__contents}>宝塚歌劇団に91期生として首席入団。<br />
            花組公演「マラケシュ・紅の墓標／エンター・ザ・レビュー」で初舞台。<br />
            その後、星組に配属。</span>
          </li>
          <li className={s.history__list}>
            <span className={s.history__year}>2006年</span>
            <span className={s.history__contents}>阪急阪神初詣ポスターモデルに起用される。</span>
          </li>
          <li className={s.history__list}>
            <span className={s.history__year}>2022年</span>
            <span className={s.history__contents}>7月24日、「めぐり会いは再び next generation／Gran Cantante!!」<br />
            東京公演千秋楽をもって、宝塚歌劇団を退団。退団公演では自身初となるエトワールを務めた。</span>
          </li>
        </ul>
        <div className={s.sns}>
          <div className={s.sns__label}>近況やニュースはSNSでチェック！</div>
          <ul className={s.sns__lists}>
            <li className={s.sns__list}>
              <a href="https://www.instagram.com/mitsuki_ten10/" target="_blank" rel="noopener noreferrer" className={s.sns__link}><InstagramIcon /></a>
            </li>
            <li className={s.sns__list}>
              <a href="https://www.facebook.com/profile.php?id=100089793699122" target="_blank" rel="noopener noreferrer" className={s.sns__link}><FacebookIcon /></a>
            </li>
          </ul>
        </div>
      </div>
      <div className={s.more}>
        <div className={s.more__button}>
          <button ref={refModal} onClick={()=>modalSwitch()} className={s.more__link}>ファンレター、プレゼントの受付について<ButtonArrow /></button>
        </div>
      </div>
      <figure className={s.image}>
        <span className={s.clip}>
        {isWebpSupported() ? (
          <picture>
            <source media="(min-width: 768px)" srcSet={`${process.env.PUBLIC_URL}/assets/img/footer-image@pc.webp`} width="1001" height="768" />
            <img src={`${process.env.PUBLIC_URL}/assets/img/footer-image.webp`} width="750" height="642" alt="" loading="lazy" />
          </picture>
        ) : (
          <picture>
            <source media="(min-width: 768px)" srcSet={`${process.env.PUBLIC_URL}/assets/img/footer-image@pc.jpg`} width="1001" height="768" />
            <img src={`${process.env.PUBLIC_URL}/assets/img/footer-image.jpg`} width="750" height="642" alt="" loading="lazy" />
          </picture>
        )}
        </span>
      </figure>
      <div className={s.illust} data-illust="02">
        <img src={`${process.env.PUBLIC_URL}/assets/img/illust-clover.svg`} width="55" height="92" alt="" loading="lazy" />
      </div>
    </section>
  )
}

export default Biography