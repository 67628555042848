import React, { useEffect, useRef } from "react"
import GlobalStyle from './components/blocks/GlobalStyles/GlobalStyles'
import { ScrollArrow } from './components/icons'
import Header from './components/blocks/Header'
import Anchor from './components/blocks/Anchor'
import Runner from './components/blocks/Runner'
import Footer from './components/blocks/Footer'
import Visual from './components/pages/home/Visual'
import Hero from './components/pages/home/Hero'
import Message from './components/pages/home/Message'
import Photo from './components/pages/home/Photo'
import Fanclub from './components/pages/home/Fanclub'
import Biography from './components/pages/home/Biography'
import Modal from './components/blocks/Modal'
import s from './App.module.scss'

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

const IndexPage = () => {
  const refContainer = useRef<HTMLDivElement>(null);
  const refWrapper = useRef<HTMLDivElement>(null);
  const refAnchor = useRef<HTMLUListElement>(null);
  const refRunner = useRef<HTMLDivElement>(null);

  // const [scroll, setScroll] = useState<Number>(0);

  class scrollTriggerPage {
    private dom:any
    private wrapper:any
    private anchor:any;
    private runner:any;
    private isRunning:boolean;
    private scrollTrigger:any
    private mql:any
  
    constructor(el:any, wrapper:any, anchor:any, runner:any) {
      this.dom = el;
      this.wrapper = wrapper;
      this.anchor = anchor;
      this.runner = runner;
  
      this.isRunning = false;
  
      this.scrollTrigger = null;
      
      this.mql = window.matchMedia('screen and (min-width: 768px)');
      this.checkBreakPoint = this.checkBreakPoint.bind(this);
  
      this.init();
      this.anchorScroll();
    }
  
    init() {
      
      // ブレイクポイントの瞬間に発火
      this.mql.addListener(this.checkBreakPoint);
      // 初回チェック
      this.checkBreakPoint(this.mql);
    }
  
    anchorScroll() {
      const anchorUl = this.anchor;
      const anchors = anchorUl.querySelectorAll('[href^="#"]');
      [...anchors].forEach( (anchor:any, index:number) => {
        anchor.addEventListener('click', (e:any) => {
          e.preventDefault();
          const target:any = e.target;
          const targetElm = document.querySelector(target.getAttribute('href')); // クリックしたアンカーリンクに紐づくpanelを取得
          const scrollbarWidth = window.innerWidth - document.body.clientWidth; // スクロールバーの長さを取得
          const wrapperOffsetX = targetElm.offsetLeft * ( this.dom.clientWidth / ( this.dom.clientWidth - window.innerWidth ) ) + scrollbarWidth * index; // 移動位置を取得
          const wrapperOffsetY = targetElm.offsetTop; // 移動位置を取得
          gsap.to((!this.mql.matches) ? this.dom : window, {
            scrollTo: {
              y: (!this.mql.matches) ? wrapperOffsetY : wrapperOffsetX,
              // autoKill: false
            },
            duration: (!this.mql.matches) ? .9 : 0,
            onComplete: () => {
              document.body.removeAttribute('data-menu')
            }
          });
        });
      });
    }
  
    checkBreakPoint(_mql:any) {
      const _this = this;
  
      // const setScrollValue = (_val:any) => {
      //   setScroll(_val)
      //   // console.log({scroll})
      // }
    
      if (!_mql.matches) {
        // console.log('max width 767');
  
        if (this.scrollTrigger) {
          this.scrollTrigger.scrollTrigger.kill();
          this.scrollTrigger.kill();
        }
        
        // const scrollMax = document.body.clientHeight - window.innerHeight;
        const scrollMax = this.wrapper.clientHeight - window.innerHeight;
  
        this.dom.addEventListener('scroll', function() {
          // 呼び出されるまで何もしない
          if (!_this.isRunning) {
            _this.isRunning = true
        
            // 描画する前のタイミングで呼び出してもらう
            window.requestAnimationFrame(function() {        
              // ここでなにか処理をする
              const progress = Math.ceil((_this.dom.scrollTop / scrollMax) * 100);
              _this.runner.style.left = progress + '%';      
              _this.isRunning = false

              // setScrollValue(_this.dom.scrollTop)
              // console.log(_this.dom.scrollTop);
            })
          }
        })
  
        return;
      } else {
        // console.log('min width 768');
  
        // let sections = gsap.utils.toArray('[class*="panel"]');
        const containerWidth = this.dom.offsetWidth;
        this.scrollTrigger = gsap.to(this.wrapper, {
          xPercent: -100 + ((window.innerWidth / containerWidth) * 100),
          ease: "none",
          scrollTrigger: {
            trigger: this.dom,
            pin: true,
            scrub: 1,
            // snap: 1 / (sections.length - 1),
            // base vertical scrolling on how wide the container is so it feels more natural.
            // end: "+=3500",
            end: "+=" + containerWidth,
            onUpdate: (e) => {
              this.runner.style.left = e.progress * 100 + '%';
            }
          },
        });
  
        return;
      }
    }
  }

  useEffect(() => {
    new scrollTriggerPage(refContainer.current, refWrapper.current, refAnchor.current, refRunner.current);
  });
  
  return (
    <>
      <GlobalStyle />
      <Header />
      <Visual />
      <Anchor ref={refAnchor} />
      <div ref={refContainer} className={s.container}>
        <div ref={refWrapper} className={s.wrapper}>
          <div className={s.panel} id="anchor-01" data-contents="top"><Hero /></div>
          <div className={s.panel} id="anchor-xx" data-contents="message"><Message /></div>
          <div className={s.panel} id="anchor-02" data-contents="photo"><Photo /></div>
          <div className={s.panel} id="anchor-03" data-contents="fanclub"><Fanclub /></div>
          <div className={s.panel} id="anchor-04" data-contents="biography"><Biography /></div>
          {/* <div className={s.panel} id="anchor-04" data-contents="biography"><Biography setModal={setModal} /></div> */}
        </div>
      </div>
      <Runner ref={refRunner} />
      {/* {modal ? 'true' : 'false'} */}
      <ScrollArrow />
      {/* <Modal setModal={setModal} /> */}
      <Modal />
      <Footer />
    </>
  );
}

export default IndexPage
