type Props = {
  width?: number
  height?: number
  fill?: string
  className?: string
}

const ButtonArrow = ({ width, height, fill, className }:Props) => (
  <svg
    className={`svg-icon svg-icon-button-arrow ${className ? className : ''}`}
    width={width}
    height={height}
    viewBox="0 0 25.456 25.456"
    fill={fill}
    role="img"
    aria-label="ボタン矢印"
  >
    <path d="M7.512,7.512,8.752,0,18,18,0,8.752Z" transform="translate(0 12.728) rotate(-45)"/>
  </svg>
)

export default ButtonArrow
