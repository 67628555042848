type Props = {
  width?: number
  height?: number
  fill?: string
  className?: string
}

const ScrollArrow = ({ width, height, fill, className }:Props) => (
  <svg
    className={`svg-icon svg-icon-scroll-arrow ${className ? className : ''}`}
    width={width}
    height={height}
    viewBox="0 0 46 25"
    fill={fill}
    role="img"
    aria-label="スクロール矢印"
  >
    <path d="M45.192 0.247993C38.232 8.63299 30.692 16.469 23.028 24.193C19.048 20.173 15.061 16.156 11.235 11.986C8.114 8.57799 3.671 3.75199 0.806 0.192991C8.43559 6.52203 23.0271 20.6513 23.0271 20.6513C23.0271 20.6513 37.4502 6.67617 45.192 0.247993Z" fill="black"/>
  </svg>
)

export default ScrollArrow
