import s from './Header.module.scss'
import frameLT from '../../../assets/img/frame-left-top.svg'
import frameRT from '../../../assets/img/frame-right-top.svg'
import frameLB from '../../../assets/img/frame-left-bottom.svg'
import frameRB from '../../../assets/img/frame-right-bottom.svg'

const bgImg:any = {
  '--image-frameLT-url': `url(${frameLT})`,
  '--image-frameRT-url': `url(${frameRT})`,
  '--image-frameLB-url': `url(${frameLB})`,
  '--image-frameRB-url': `url(${frameRB})`
}

const Header = () => {
  const onClickHandler = () => {
    const body = document.body;
    if (body.dataset.menu) {
      body.removeAttribute('data-menu')
    } else {
      body.dataset.menu = 'open'
    }
  }
  
  return (
    <header style={bgImg} className={s.header}>
      <div className={s.inner}>
        <h1 className={s.title}>mitsuki tenju Official website</h1>
        <button type="button" className={s.trigger} onClick={onClickHandler}>
          <img src={`${process.env.PUBLIC_URL}/assets/img/menu.svg`} width="160" height="160" alt="" />
        </button>
      </div>
    </header>
  )
}

export default Header