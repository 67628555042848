import React, { forwardRef } from "react";
import s from './Runner.module.scss'

const Runner = forwardRef<HTMLDivElement>((props, refRunner) => {
  
  return (
    <div className={s.illust}>
      <div ref={refRunner} className={s.illust__runner}>
        <img src={`${process.env.PUBLIC_URL}/assets/img/illust-run.png`} width="105" height="158" alt="" />
      </div>
    </div>
  )
})

export default Runner