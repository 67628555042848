import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Bellefair&family=Zen+Kaku+Gothic+Antique:wght@400;500&display=swap');

  // CSS変数
  :root {
    --color-bg-gray: rgba(0, 0, 0, .28);
    --color-bg-lightgray: #EDEDED;
    --color-bg-modal: rgba(243, 243, 243, .7);
    --color-menu: rgba(0, 0, 0, .32);
    --color-button: #F3F3F3;
    --color-button-hover: #000;
    --color-frame: #F3F3F3;
    --color-font: #000;
    --color-border: #000;
    --color-border-dot: #707070;
    --color-sns-bg: #E8E8E8;
  
    --font-family: 'Zen Kaku Gothic Antique', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'メイリオ', Meiryo, sans-serif;
    --font-family-bellefair: 'Bellefair', serif;
    --font-family-zenkaku: 'Zen Kaku Gothic Antique', sans-serif;
  
    --ease-01: cubic-bezier(0, 0.4, 0.25, 1);
    --ease-02: cubic-bezier(0, 0.8, 0.4, 1.0);
    --ease-accordion: cubic-bezier(0.7, 0, 0.1, 1);
    --ease-slide: cubic-bezier(0.83,0.28,0.31,0.99);
    --ease-menu: cubic-bezier(0.390, 0.575, 0.565, 1.000);
  }

  html { font-size: calc(14px + (200vw - 640px) / 55); }
  // Mobile (Large Display / Landscape Mode)
  @media print, screen and (min-width: 375px) { html { font-size: 16px; } }
  // Tabret & PC (Middle Display)
  @media print, screen and (min-width: 768px) { html { font-size: 1.166vw; } }
  // PC (Large Display)
  @media print, screen and (min-width: 1200px) { html { font-size: calc(14px + (200vw - 2400px) / 240); } }
  @media print, screen and (min-width: 1366px) { html { font-size: 16px; } }

  body {
    background-color: var(--color-bg-gray);
    overscroll-behavior: none;
    height: 100%;
    margin: 0;
    font-family: var(--font-family);
    line-height: 1;
    color: var(--color-font);
    word-wrap: break-word;
    font-feature-settings: "palt";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
  }

  *::before, *::after {
    box-sizing: border-box;
  }

  main, picture {
    display: block;
  }
  
  h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
    line-height: 1.25;
    letter-spacing: 0;
  }
  
  p {
    margin: 0;
    line-height: 1.5;
  }
  
  ul, ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
    line-height: 1.5;
  }
  
  img, svg {
    max-width: 100%;
    height: auto;
    line-height: 1;
    vertical-align: top;
    border: none;
  
    &[width="0"] {
      width: 0;
      height: 0;
      position: absolute;
      left: 0;
      top: 100%;
    }
  }
  
  iframe {
    border: none;
  }
  
  figure {
    margin: 0;
  }
  
  textarea {
    vertical-align: top;
    resize: vertical;
  }
  
  a {
    color: currentColor;
    text-decoration: none;
  
    &[href^='tel:'] {
      cursor: default;
    }
  }
`;

export default GlobalStyle;