import {isWebpSupported} from 'react-image-webp/dist/utils';
import s from './Photo.module.scss'

const Photo = () => {
  return (
    <div className={s.root}>
      <figure className={s.image}>
        <span className={s.clip}>
        {isWebpSupported() ? (
          <picture>
            <source media="(min-width: 768px)" srcSet={`${process.env.PUBLIC_URL}/assets/img/photo-image@pc.webp`} width="984" height="768" />
            <img src={`${process.env.PUBLIC_URL}/assets/img/photo-image.webp`} width="750" height="1080" alt="" loading="lazy" />
          </picture>
        ) : (
          <picture>
            <source media="(min-width: 768px)" srcSet={`${process.env.PUBLIC_URL}/assets/img/photo-image@pc.jpg`} width="984" height="768" />
            <img src={`${process.env.PUBLIC_URL}/assets/img/photo-image.jpg`} width="750" height="1080" alt="" loading="lazy" />
          </picture>
        )}
        </span>
      </figure>
    </div>
  )
}

export default Photo