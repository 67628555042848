import {isWebpSupported} from 'react-image-webp/dist/utils';
import s from './Message.module.scss'

const Message = () => {
  return (
    <div className={s.root}>
      <p className={s.text}>
      {isWebpSupported() ? (
        <picture>
          <source media="(min-width: 768px)" srcSet={`${process.env.PUBLIC_URL}/assets/img/message@pc.webp`} width="679" height="617" />
          <img src={`${process.env.PUBLIC_URL}/assets/img/message.webp`} width="732" height="2723" alt="" loading="lazy" />
        </picture>
      ) : (
        <picture>
          <source media="(min-width: 768px)" srcSet={`${process.env.PUBLIC_URL}/assets/img/message@pc.png`} width="679" height="617" />
          <img src={`${process.env.PUBLIC_URL}/assets/img/message.png`} width="732" height="2723" alt="" loading="lazy" />
        </picture>
      )}
      </p>
      <div className={s.illust} data-illust="01">
        <img src={`${process.env.PUBLIC_URL}/assets/img/illust-message-01.svg`} width="50" height="72" alt="" loading="lazy" />
      </div>
      <div className={s.illust} data-illust="02">
        <img src={`${process.env.PUBLIC_URL}/assets/img/illust-message-02.svg`} width="32" height="75" alt="" loading="lazy" />
      </div>
    </div>
  )
}

export default Message