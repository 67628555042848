import React from 'react'
import ReactDOM from 'react-dom/client'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // 参考 StrictMode
  // https://marsquai.com/745ca65e-e38b-4a8e-8d59-55421be50f7e/f83dca4c-79db-4adf-b007-697c863b82a5/84dda80d-d8a0-4a1a-a042-66848dc6afe9/
  // <React.StrictMode>
  <HelmetProvider>
    <Helmet>      
      <link rel="preload" href={`${process.env.PUBLIC_URL}/assets/img/visual/03.mp4`} as="video" />
      <link rel="preload" href={`${process.env.PUBLIC_URL}/assets/img/visual/11.mp4`} as="video" />
      <link rel="preload" href={`${process.env.PUBLIC_URL}/assets/img/visual/20.mp4`} as="video" />
      {/* <link rel="preload" href={`${process.env.PUBLIC_URL}/assets/img/visual/23.mp4`} as="video" />
      <link rel="preload" href={`${process.env.PUBLIC_URL}/assets/img/visual/29.mp4`} as="video" /> */}
    </Helmet>
    <App />
  </HelmetProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
