import React, { forwardRef } from "react";
import {isWebpSupported} from 'react-image-webp/dist/utils';
import { InstagramIcon, FacebookIcon } from '../../icons'
import s from './Anchor.module.scss'

const Anchor = forwardRef<HTMLUListElement>((props, refAnchor) => {
  return (
    <div className={s.root}>
      <nav className={s.navigation}>
        <ul ref={refAnchor} className={s.lists}>
          <li className={s.list}>
            <a href="#anchor-01" className={s.anchor}>Top</a>
          </li>
          {/* <li className={s.list}>
            <a href="#anchor-02" className={s.anchor}>Photo</a>
          </li> */}
          <li className={s.list}>
            <a href="#anchor-02" className={s.anchor}>Fan Club</a>
          </li>
          <li className={s.list}>
            <a href="#anchor-04" className={s.anchor}>Biography</a>
          </li>
        </ul>
        <ul className={s.sns}>
          <li className={s.sns__list}>
            <a href="https://www.instagram.com/mitsuki_ten10/" target="_blank" rel="noopener noreferrer" className={s.sns__link}><InstagramIcon fill="#fff" /></a>
          </li>
          <li className={s.sns__list}>
            <a href="https://www.facebook.com/profile.php?id=100089793699122" target="_blank" rel="noopener noreferrer" className={s.sns__link}><FacebookIcon fill="#fff" /></a>
          </li>
        </ul>
      </nav>
      <div className={s.illust}>
      {isWebpSupported() ? (
        <img src={`${process.env.PUBLIC_URL}/assets/img/illust-tark.webp`} width="150" height="150" alt="" loading="lazy" />
      ) : (
        <img src={`${process.env.PUBLIC_URL}/assets/img/illust-tark.png`} width="150" height="150" alt="" loading="lazy" />
      )}
      </div>
    </div>
  )
})

export default Anchor