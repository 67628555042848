type Props = {
  width?: number
  height?: number
  fill?: string
  className?: string
}

const FacebookIcon = ({ width, height, fill, className }:Props) => (
  <svg
    className={`svg-icon svg-icon-facebook ${className ? className : ''}`}
    width={width}
    height={height}
    viewBox="0 0 40 39.754"
    fill={fill}
    role="img"
    aria-label="FacebookIcon"
  >
    <path d="M1690.989,658.149a20,20,0,1,0-23.12,19.761V663.931h-5.083v-5.782h5.083v-4.407c0-5.014,2.983-7.783,7.55-7.783a30.74,30.74,0,0,1,4.475.391v4.921h-2.522c-2.484,0-3.26,1.542-3.26,3.124v3.752h5.551l-.892,5.782h-4.659v13.978A20.006,20.006,0,0,0,1690.989,658.149Z" transform="translate(-1650.989 -638.156)"/>
  </svg>
)

export default FacebookIcon
