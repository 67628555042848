import s from './Footer.module.scss'

const Footer = () => {
  return (
    <footer className={s.footer}>
      <ul className={s.sns}>
        <li className={s.list}>
          <a href="https://www.instagram.com/mitsuki_ten10/" target="_blank" rel="noopener noreferrer" className={s.link}>Instagram</a>
        </li>
        <li className={s.list}>
          <a href="https://www.facebook.com/profile.php?id=100089793699122" target="_blank" rel="noopener noreferrer" className={s.link}>Facebook</a>
        </li>
      </ul>
      <p className={s.copyright}>
        <small className={s.copyright__inner}>©Mitsuki tenju All rights reserved.</small>
      </p>
    </footer>
  )
}

export default Footer