import s from './Hero.module.scss'

const Hero = () => {
  return (
    <div className={s.root}>
      <div className={s.logo}>
        <img src={`${process.env.PUBLIC_URL}/assets/img/logo.svg`} width="384" height="165" alt="天寿光希 Official Website" />
      </div>
      {/* <div className={s.visual}>
        <span className="c-clip">
          <img src={`${process.env.PUBLIC_URL}/assets/img/visual-01.jpg`} width="630" height="1080" alt="" />
        </span>
      </div>       */}
    </div>
  )
}

export default Hero